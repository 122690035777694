import Link from 'next/link';
import {FC, RefObject, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import styles from './MainMenu.module.scss'
import {BorderType, Screen} from "../Screen/Screen";
import {ActionButton} from "../ActionButton/ActionButton";
import {clsIf, clss} from "../../src/utils";
import {useDataKeeper} from "../../pages/_app";
import burgerIcon from "../../public/images/icons/menu.svg";
import xIcon from "../../public/images/icons/x.svg";
import Image from 'next/image';
import * as gtag from "../../src/gtag";
import {useSmoothScroll} from "../SmoothScroll/SmoothScroll";

type LinkDescriptor = {
  name: string
  href?: string
  as?: string
  label: string
  notification?: boolean
  sublinks?: LinkDescriptor[]
};

const MenuLink: FC<{
  descriptor: LinkDescriptor
  activeHref: string
}> = ({
  descriptor: link,
  activeHref,
}) => {
  const [submenuShown, setSubmenuShown] = useState(false);

  let linkContent;
  let active = activeHref == link.href;
  if (active || !link.href) {
    let onClick = link.sublinks ? () => {
      setSubmenuShown(!submenuShown)
    } : () => {
    };
    linkContent = <div className={styles.linkContent} onClick={onClick}><span className={styles.label}>{link.label}</span></div>
  } else {
    linkContent = <Link href={link.href} as={link.as} prefetch={false}>
      <a className={styles.linkContent} onClick={() => {
        gtag.event({
          action: "internal_navigate",
          label: link.name,
          label2: "header"
        })
      }}><span className={styles.label}>{link.label}</span></a>
    </Link>
  }
   if (link.sublinks) {
    linkContent = <>
      {linkContent}
      <ul className={clss(styles.submenu)} onMouseLeave={() => setSubmenuShown(false)}>
        {/*{link.sublinks.map(sl => <li><a><span className={styles.label}>{sl.label}</span></a></li>)}*/}
        {link.sublinks.map(sl => <MenuLink descriptor={sl} activeHref={activeHref}/>)}
      </ul>
    </>
  }

  return <li className={clss(styles.link, clsIf(active, styles.active), clsIf(link.notification, styles.withNotification), clsIf(link.sublinks, styles.withSubmenu), clsIf(submenuShown, styles.submenuShown))}>
  {linkContent}
</li>
}

export const MainMenu: FC<{contactsRef: RefObject<HTMLDivElement>, fixedHeader?: boolean}> = ({contactsRef, fixedHeader}) => {
  let links: (LinkDescriptor)[] = [
    {name: 'about', href: '/', label: 'About', as: '/about'},
    {name: 'hut', href: '/hut', label: 'Hut'/*, notification: true*/},
    /*{
      label: 'Services',
      name: 'services',
      sublinks: [
        {name: 'card', href: '/card', label: 'Card'},
        {name: 'travelation', href: '/travelation', label: 'Travelation'},
      ],
    },*/
    {name: 'academy', href: '/academy', label: 'Academy'},
    {name: 'events', href: '/events', label: 'Events'},
  ];
  const router = useRouter();
  const [menuShown, setMenuShown] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false);

  const value = useDataKeeper()

  useEffect(() => {
/*    setTimeout(() => {
      if (!window["redirectsCounter"]) {
        setNotificationShown(true)
      }
    }, 1000)*/
  }, [value]);

  const withSmoothScroll =  useSmoothScroll();

  let onContactClick = () => {
    withSmoothScroll(() => {
      contactsRef.current!.scrollIntoView();
    })
    gtag.event({
      action: "internal_navigate",
      label: 'contacts',
      label2: "header"
    })
  };
  return (
    <Screen wide={true} borderType={fixedHeader ? BorderType.FIXED_HEADER : BorderType.HEADER} id={styles.MainMenu} microdataItemType={"https://schema.org/WPHeader"} role={"banner"}>
      <nav className={clss(styles.MainMenu, clsIf(menuShown, styles.menuShown), clsIf(fixedHeader, styles.fixed))}>
        <a className={styles.logo} href={"/"}>
          Digital <span className={styles.barbaros}>Barbaros</span>
        </a>
        <ul itemScope={true} itemType="https://schema.org/SiteNavigationElement" className={styles.linksList}>
          {links.map(link => {
              return <MenuLink key={link.label} activeHref={router.pathname} descriptor={link}/>
          })}
          <li className={clss(styles.link, styles.contactUsLi)}>
            <a className={styles.linkContent} onClick={onContactClick}><span className={styles.label}>Contact us</span></a>
          </li>
        </ul>
        <div className={styles.buttons}>
          <div
            className={clss(styles.burgerMenu, clsIf(links.find(l => l.notification), styles.withNotification), clsIf(menuShown, styles.shown))}
            onClick={() => {
              setMenuShown(!menuShown);
              setNotificationShown(false);
            }}>
            <Image src={menuShown ? xIcon : burgerIcon} width={24} height={24} alt={""} loading={"lazy"}/>
          </div>
          <div className={styles.contactUs}>
            <ActionButton label={'Contact us'} small={true} onClick={onContactClick}/>
          </div>
        </div>
        <div className={clss(styles.hintPopover, clsIf(notificationShown, styles.shown))}>
          <div className={styles.header}><a className={styles.closeBtn} onClick={() => setNotificationShown(false)}>
            <Image src={xIcon} width={20} height={20} alt={""} loading={"lazy"}/>
          </a>
          </div>
          <div className={styles.content}>
            Join us at Barbaros Hut in July:
            <br/>
            secure your Portuguese workation experience!
            <br/>
            <br/>
            <b>Only 5 rooms left</b>
          </div>
        </div>
      </nav>
    </Screen>
  );
};
