import {TravelDto} from "./dto";

export function clsIf(condition: any, className: string) {
  return !!condition ? className : '';
}

export function clss(...classNames: (string | undefined)[]) {
  return classNames.filter((val) => val).join(' ')
}

export function uniqueCountries(countries?: TravelDto[]) {
  if (!countries) return [];
  const uniqueCountries = {}
  // @ts-ignore
  countries.forEach(c => uniqueCountries[c.country] = (uniqueCountries[c.country] || 0) + 1 )
  return Object.keys(uniqueCountries)
}

const uuidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export function isUuid(val: string) {
  return uuidRegexExp.test(val)
}

const emailReg = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export function isEmail(val: string) {
  return emailReg.test(val)
}