import {FC} from 'react';
import styles from './SocialIcons.module.scss'
import Image from "next/image";
import {clss} from "../../src/utils";
import * as gtag from "../../src/gtag";


export const SocialIcons: FC<{ className?: string, analyticsPlacement: string }> = ({
  className, analyticsPlacement
}) => {
  return <div className={clss(styles.social, className)}>
    <a href="https://www.linkedin.com/company/digital-barbaros/" target="_blank" aria-label="LinkedIn"
       onClick={() => {
         gtag.event({
           action: "social",
           label: 'linkedin',
           label2: analyticsPlacement
         })
       }}
    >
      <Image src={`/images/social/linkedin.svg`} width={40} height={40} alt={"LinkedIn"}/>
    </a>
    <a href="https://www.facebook.com/digital.barbaros" target="_blank" aria-label="Facebook"
       onClick={() => {
         gtag.event({
           action: "social",
           label: 'facebook',
           label2: analyticsPlacement
         })
       }}
    >
      <Image src={`/images/social/facebook.svg`} width={40} height={40} alt={"Facebook"}/>
    </a>
    <a href="https://www.instagram.com/digital.barbaros/" target="_blank" aria-label="Instagram"
       onClick={() => {
         gtag.event({
           action: "social",
           label: 'instagram',
           label2: analyticsPlacement
         })
       }}
    >
      <Image src={`/images/social/instagram.svg`} width={40} height={40} alt={"Instagram"}/>
    </a>
  </div>
}
