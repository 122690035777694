import {ImageProps} from "next/dist/client/image";
import {useState} from "react";
import Image from "next/image";
import {clsIf} from "../../src/utils";
import styles from './ImageWithBlur.module.scss';

export function ImageWithBlur(props: ImageProps) {
  const [loaded, setLoaded] = useState(false);

  return <Image src={props.src} draggable={false} alt={props.alt} loading={props.loading}
                layout={props.layout} objectFit={props.objectFit} sizes={props.sizes}
                placeholder={"blur"} className={clsIf(loaded, styles.unblur)} onLoadingComplete={() => setLoaded(true)}
  />
}