import styles from './Screen.module.scss'
import {clsIf, clss} from "../../src/utils";
import {forwardRef, PropsWithChildren, useEffect, useRef} from "react";
import useWindowDimensions from "../../src/useWindowDimentions";
import Image from "next/image";

export enum BorderType {
  HEADER,
  FIXED_HEADER,
  FOOTER,
}

export const Screen = forwardRef<HTMLDivElement, PropsWithChildren<{
  gray?: boolean,
  id?: string,
  borderType?: BorderType,
  wrapperClass?: string,
  wide?: boolean,
  fullHeight?: boolean,
  backgroundSrc?: any,
  microdataItemType?: string,
  role?: string,
    className?: string,
}>>(({
  children,
  gray,
  id,
  borderType,
  wrapperClass,
  wide,
  fullHeight,
  backgroundSrc,
  microdataItemType,
  role,
  className,
}, ref) => {
  let {height} = useWindowDimensions();
  let innerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (fullHeight) {
      innerRef.current!.style.height = height + "px";
      innerRef.current!.parentElement!.style.height = height+ "px";
    }
  }, [height])

  let div = <>
    <div className={clss(styles.screenWrapper,
      clsIf(gray, styles.gray),
      clsIf(borderType == BorderType.HEADER || borderType == BorderType.FIXED_HEADER, styles.borderHeader),
      clsIf(borderType == BorderType.FOOTER, styles.borderFooter),
      clsIf(fullHeight, styles.fullHeight),
      wrapperClass,
    )} ref={ref}>
      <div className={clss(styles.screen, clsIf(wide, styles.wide), className,)} id={id}
           ref={innerRef}>
        {children}
        <div className={styles.background}>{backgroundSrc &&
            <Image src={backgroundSrc} draggable={false} objectFit={"cover"} objectPosition={"center"} alt={""}
                   layout={"fill"}/>}</div>
      </div>
    </div>
  </>;
  return borderType == BorderType.HEADER || borderType == BorderType.FIXED_HEADER ?
    <header
      itemScope={!!microdataItemType} itemType={microdataItemType} role={role}
      className={clss(clsIf(borderType == BorderType.FIXED_HEADER, styles.fixedHeader))}
    >{div}</header> : borderType == BorderType.FOOTER ?
      <footer
        itemScope={!!microdataItemType} itemType={microdataItemType} role={role}
      >{div}</footer> :
      div;
});
