import {FC, PropsWithChildren, RefObject, Suspense, useRef} from 'react';
import Head from 'next/head';
import {MainMenu} from '../MainMenu/MainMenu';
import Footers from "../Footers/Footers";
import {clsIf, clss} from "../../src/utils";
import styles from './Layout.module.scss'

export const Layout: FC<PropsWithChildren<{
  title: string
  subUrl: string
  contactsRef?: RefObject<HTMLDivElement>
  noindex?: boolean,
  description?: string,
  bodyItemType?: string,
  ldJson?: any,
  fixedHeader?: boolean,
}>> = ({
  title,
  subUrl,
  children,
  contactsRef,
  noindex,
  description,
  bodyItemType,
  ldJson,
  fixedHeader,
}) => {
  // bodyItemType = bodyItemType || 'https://schema.org/WebPage';

  if (!subUrl.startsWith("/")) subUrl = "/" + subUrl
  if (!subUrl.endsWith("/")) subUrl = subUrl + "/"
  const footerContactsRef = useRef(null)


  const defaultDescription = 'Digital Barbaros is the premiere coliving and coworking serving digital nomads worldwide. We offer networking events, online services, and more. Connect today'
  const computedTitle = title
  return (<div>
    <Head>
      <meta property="og:site_name" content="Digital Barbaros"/>

      <meta name="description" content={description || defaultDescription}/>
      <meta name="twitter:description" content={description || defaultDescription}/>
      <meta property="og:description" content={description || defaultDescription}/>

      <meta property="twitter:image" content="https://barbaros.satelstories.com/og-image.jpg"/>
      <meta property="og:image" content="https://barbaros.satelstories.com/og-image.jpg"/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>

      <meta name="twitter:card" content="summary"/>
      <meta property="og:type" content="website"/>
      <meta name="robots" content={noindex ? "noindex" : "index, follow"}/>
      <meta property="og:url" content={`https://barbaros.satelstories.com${subUrl}`}/>
      <link rel="canonical" href={`https://barbaros.satelstories.com${subUrl}`}/>
      <title>{computedTitle}</title>
      <meta property="og:title" content={computedTitle}/>
      <meta property="twitter:title" content={computedTitle}/>

      {ldJson && <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(ldJson)}}></script>}
    </Head>

    <main itemScope={!!bodyItemType} itemType={bodyItemType} className={clss(clsIf(fixedHeader, styles.withFixedHeader))}>
      <MainMenu contactsRef={contactsRef || footerContactsRef} fixedHeader={fixedHeader}/>
      {children}
      <Suspense fallback={<></>}>
        <Footers ref={footerContactsRef} />
      </Suspense>
    </main>
  </div>)
}
