import {FC} from 'react';
import styles from './ActionButton.module.scss';
import Link from "next/link";
import {clsIf, clss} from "../../src/utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const ActionButton: FC<{
  label?: string, small?: boolean, href?: string, as?: string, onClick?: any, disabled?: boolean,
  ariaLabel?: string
  size?: 'input'| 'large' | 'smallInput',
  bitbtn?: boolean,
  download?: string,
  icon?: any,
  openInNewTab?: boolean
}> = ({
  label,
  ariaLabel,
  small,
  href,
  as,
  onClick,
  disabled,
  size,
  bitbtn,
  download,
  icon,
  openInNewTab,
}) => {
  if (!href && !onClick) {
    throw new Error("At least one of href/onClick should be set");
  } else if (download && !href) {
    throw new Error("Use href to create download link");
  } else {
    let classNames = clss(styles.actionButton, clsIf(small, styles.small), clsIf(bitbtn, styles.bitbtn), clsIf(disabled, styles.disabled),
      clsIf(size == 'input', styles.inputSize), clsIf(size == 'smallInput', styles.smallInput), clsIf(size == 'large', styles.large));
    let labelContent = icon ? <><FontAwesomeIcon icon={icon}/> {label}</> : label;
    if (href) {
      return (<Link href={href} as={as}>
        <a className={classNames} download={download} target={download || openInNewTab ? "_blank" : undefined} aria-label={ariaLabel} onClick={onClick}>{labelContent}</a>
      </Link>);
    } else if (onClick) {
      return (<a className={classNames} onClick={onClick} aria-label={ariaLabel}>{labelContent}</a>);
    } else {
      throw new Error("One of href/onClick should be set");
    }
  }
};
