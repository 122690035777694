import Link from 'next/link';
import {FC, forwardRef} from 'react';
import styles from './Footers.module.scss'
import {BorderType, Screen} from "../Screen/Screen";
import Image from "next/image";
import {SocialIcons} from "../SocialIcons/SocialIcons";
import bckFooter from "../../public/images/bck-footer.png";
import * as gtag from "../../src/gtag";
import {ImageWithBlur} from "../ImageWIthBlur/ImageWithBlur";

const Footers = forwardRef<HTMLDivElement, {}>((props, ref) => {
  let links = [
    { name: 'about', href: '/', label: 'About us', as: '/about' },
    { name: 'hut', href: '/hut', label: 'Barbaros Hut' },
    /*{ name: 'card', href: '/card', label: 'Digital Barbaros Card' },*/
    { name: 'academy', href: '/academy', label: 'Barbaros Academy' },
    { name: 'events', href: '/events', label: 'Events' },
    { name: 'blog', href: '/blog', label: 'Blog' },
  ];

  return (<>
    <Screen id={styles.footer} gray={true} borderType={BorderType.FOOTER} ref={ref} microdataItemType="https://schema.org/WPFooter">
      <div className={styles.backgroundContainer}>
        <ImageWithBlur src={bckFooter} draggable={false} loading={"lazy"} alt={""}/>
      </div>
      <div className={styles.footerCol}>
        <div className={styles.logo}>
          <h3>Digital <span className={styles.barbaros}>Barbaros</span></h3>
          <div className={styles.slogan}>An ecosystem for digital specialists all over the world.</div>
        </div>
        <ul className={styles.navigation} itemScope={true} itemType="https://schema.org/SiteNavigationElement">
          {links.map(link => <li key={link.href + link.as}>
            <Link href={link.href} as={link.as}>
              <a onClick={() => {
                gtag.event({
                  action: "internal_navigate",
                  label: link.name,
                  label2: "footer"
                })
              }}>
              {link.label}
              </a>
            </Link>
          </li>)}
        </ul>
      </div>

      <div className={styles.contact}>
        <div>Follow us</div>
        <SocialIcons analyticsPlacement={"footer"}/>
        <div>Contact us</div>
        <div>
          <a href="mailto:welcome@satelstories.com">welcome@satelstories.com</a>
        </div>
      </div>
    </Screen>

  <Screen id={styles.copyright}>
    Copyright ® 2022 Digital Barbaros All rights Reserved
  </Screen>
  </>
  );
});


export default Footers;